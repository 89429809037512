import React, { useState } from 'react';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import { Link } from 'gatsby';
import { sendPasswordResetEmail } from 'firebase/auth';

import SEO from '../../components/SEO';
import Auth, { Group } from '../../components/Auth';
import { auth } from '../../utils/fb';

export default function RequestPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  function validateEmail(email: any) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setError(false);
    setSuccess(false);

    if (!email) {
      setError(true);
      return;
    }
    if (!validateEmail(email)) {
      setError(true);
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess(true);
      setEmail('');
    } catch (error) {
      setError(true);
    }
  };
  return (
    <Auth title="Forgot Password" subTitle="Enter your email address and we’ll send a link to reset your password">
      <SEO title="Forgot Password" />
      {!success ? (
        <form onSubmit={handleSubmit}>
          <InputGroup fullWidth>
            <input
              type="email"
              placeholder="Email Address"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>
          {error && <p>Valid Email is required</p>}
          <Button status="Success" type="submit" shape="SemiRound" fullWidth>
            Request Password
          </Button>
        </form>
      ) : (
        <p>An e-mail is sent containing password reset instructions</p>
      )}
      <Group>
        <Link to="/auth/login">Back to Log In</Link>
        <Link to="/auth/register">Register</Link>
      </Group>
    </Auth>
  );
}
